<template>
  <div>
    <user-list />
  </div>
</template>

<script>
import UserList from '@/views/user/users-list/UsersList.vue'

export default {
  components: {
    UserList,
  },
}
</script>

<style lang="scss" scoped>

</style>
